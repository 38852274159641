import React, {useState} from 'react'
import Button from './Button'
import Star from '../components/star/Star'

const Card = ({ img, badge, heading, stars, reviews, price,classname, hoverImg }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
     
    return (
        <>
        <div className={`card ${classname}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <small>{badge}</small>
            <img src={isHovered ? hoverImg : img} alt="" />
            <h4>{heading}</h4>
            <Star stars={stars} />
            <p>{reviews}</p>
            
            <h5 className='price'>${price} <span>${price}</span></h5> 
          
            <Button name="ADD TO BAG"/>
        </div>


</>
    )
}

export default Card
