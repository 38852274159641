import React from 'react'

const Button = ({name,className, icon }) => {
  return (
<button className={`button ${className}`}>{icon} {name}</button>
  )
}

export default Button

