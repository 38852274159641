import React from 'react'
import Heading from '../../utility/Heading'
import Card from '../../utility/Card'
import '../toppicks/toppicks.css'

import data from '../data/data'
import Button from '../../utility/Button'

const TopPicks = () => {
  return (


    <div>
        
     <Heading  name="Our Top Picks"/>

     
     <div className='card-container'>
      {
          data.map(({img, name, heading, price, id , reviews, hoverImg}) => {
            return(
                    <Card key={id} img={img}
                    hoverImg={hoverImg}
                    badge={name} heading={heading} stars="5 star" reviews={reviews} price={price} />
            )
          })
      }
        
        
     </div>


     <div className='explore'> 
      <Button name="EXPLORE MORE" />
     </div>
    </div>
  )
}

export default TopPicks
