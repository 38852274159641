import React from 'react'
import '../Belive/belive.css'
import img from '../../images/clean-cosmetic-01.png'
import img1 from '../../images/cruelty-free-01.png'
import img2 from '../../images/vegan-01.png'
import img3 from '../../images/dermato-tested-01.png'
import img4 from '../../images/recycle-01.png'


const Belive = () => {
  return (

    <div className='contain'> 
    <div className='belive'>
      <h2>
      BELIEVE IN BETTER
      </h2>

      <div className="image_contain">

    
        <div className="believe-text">
        <img src={img} alt="" />
        <p>CRUELTY
FREE</p>
        </div>

        <div className="believe-text">
        <img src={img2} alt="" />
        <p>VEGAN</p>
        </div>

        <div className="believe-text">
        <img src={img3} alt="" />
        <p className='pp'>DERMATOLOGIST
TESTED</p>
        </div>

        <div className="believe-text">
        <img src={img4} alt="" />
        <p>RECYCLE</p>
        </div>

        <div className="believe-text">
        <img src={img1} alt="" />
        <p>CLEAN
INGREDIENTS</p>
        </div>
      </div>
    </div>

    
    </div>
  )
}

export default Belive
