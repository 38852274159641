import React from 'react'

const Heading = ({name,name2, className}) => {
  return (
    <div>
      <h1 className={`head ${className}`}>{name}</h1>
      <h3 className={`headings-second ${className}`}>{name2}</h3>
    </div>
  )
}

export default Heading
