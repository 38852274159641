import React from 'react'
import Heading from '../../utility/Heading'
import { Container } from '@mui/material'
import img from '../../images/exclusive.webp'
import Star from '../star/Star'
import Button from '../../utility/Button'
import '../exclusive/exclusive.css'
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai'

const Exclusive = () => {
  return (
    <div>

        <Heading name2="Exclusives" />
        <Heading name="VALUE AND GIFT SETS" className="headingsvalue"/>

        <Container className='container'>
          <div className='detail-section'>
          
          <div className="product-image">

          <img src={img} alt="" />
          </div>
          

          <div className="product-data">

           <h3>See Love Minis Kit</h3>

           <Star />

           <p className='price'>Rs. 1,000.00 <span><del>Rs. 2,000.00</del></span></p>

           

         
           <p className='taxes'>Inclusve of all taxes</p>

           <p className='offer'>Offer: Add 2 To Cart Pay for 1</p>

           <div className='hr'> </div>

           <p>This special starter set is a mini introduction to See Love skincare made with Kind-to-Skin ingredients for all your skincare needs, in handy travel sizes. Lovingly packed in a pretty peach See Love Pouch, handmade by artisans in collaboration with The Burlap People, it’s also perfect for gifting or holiday travel!</p>


           <div className="cartinc">
           <AiOutlineMinus /> <input type="text" placeholder='1' /> <AiOutlinePlus />
           </div>


           <Button name="ADD TO BAG" />


          </div>
     


          </div>

        </Container>
      
    </div>
  )
}

export default Exclusive
