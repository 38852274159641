import img from '../../images/category-1.jpg'
import img1 from '../../images/category-2.jpg'
import img2 from '../../images/category-3.jpg'


 const CategoryCard = [

    {
        id: 1,
        title: 'Mosturisers',
        img: img,
        link: '/mosturiser',
        
    },
    {
        id: 2,
        title: 'Cleansers',
        img: img1,
        link: '/cleanser',
    },
    {
        id: 3,
        title: 'Serums',
        img: img2,
        link: '/serum',
    },

]
export default CategoryCard;