import React, { useState} from 'react'
import {BsCart4 , BsSearch} from 'react-icons/bs'
import {AiOutlineUser} from 'react-icons/ai'
import img from '../../images/logo.png'
import './nav.css'
import {GoThreeBars} from 'react-icons/go'
import {MdOutlineClose} from 'react-icons/md'
import {Link} from 'react-router-dom'


const Navbar = (props) => {


  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
      if (window.scrollY >= 80) {
          setColorchange(true);
      }
      else {
          setColorchange(false);
      }
  };
  window.addEventListener('scroll', changeNavbarColor);

 const [isNavShowing, setIsNavShowing] = useState(false);

  return (
    <>
    <nav className={colorChange ? 'navbar colorChange' : 'navbar'} >

<div className="top_nav">

<div className='blank'>
<button className='nav__togle-btn' onClick={() => setIsNavShowing(!isNavShowing)}>

  {
    isNavShowing ? <MdOutlineClose /> :  <GoThreeBars />

  }
   {/* <GoThreeBars /> */}
</button>
</div>

<div className="logo">
  <img src={img} alt="" />
</div>

<div className="user_links">
  <AiOutlineUser className='icon'/>
  <BsCart4 className='icon'/>
  <BsSearch className='icon'/>
</div>


</div>

<div className={isNavShowing ? "show_nav" : "hide_nav"}>
  <ul className="nav_links">
  <li> <Link to='/' className={({isActive}) => isActive ? 'active-nav' :  ''}>Home</Link> </li>
  <li> <Link to='/shop' className={({isActive}) => isActive ? 'active-nav' :  ''}>Shop</Link> </li>
  <li> <Link to='/gallery' className={({isActive}) => isActive ? 'active-nav' :  ''}>Gallery</Link> </li>
  <li> <Link to='/about' className={({isActive}) => isActive ? 'active-nav' :  ''}>About us</Link> </li>
  <li> <Link to='/contact' className={({isActive}) => isActive ? 'active-nav' :  ''}>Contact</Link> </li>
 
  </ul>
</div>


    </nav>
    </>
  )
}

export default Navbar
