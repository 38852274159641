import img from '../../images/p1.webp'
import img1 from '../../images/p2.webp'
import img2 from '../../images/p3.webp'
import img3 from '../../images/p4.webp'
import img4 from '../../images/p5.webp'
import img5 from '../../images/p6.webp'
import img6 from '../../images/p7.webp'
import img7 from '../../images/p8.webp'
import img8 from '../../images/calm-and-repair-creme-03_600x.webp'

 const Card = [

    {
        id: 1,
        heading: 'Day Cream',
        img: img,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img5,
    },
    {
        id: 2,
        heading: 'Light Cream',
        img: img1,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img7,
    },
    {
        id: 3,
        heading: 'Foundation',
        img: img2,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img5,
    },
    {
        id: 4,
        heading: 'Serum',
        img: img3,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img5,
    },
    {
        id: 5,
        heading: 'Lotion',
        img: img1,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img8,
    },
    {
        id: 6,
        heading: 'Night Cream',
        img: img2,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img4,
    },
    {
        id: 7,
        heading: 'Night Cream',
        img: img2,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img6,
    },
    {
        id: 8,
        heading: 'Night Cream',
        img: img2,
        price: '456',
        reviews: '22 reviews',
        name: 'on Sale',
        hoverImg: img4,
    },
]
export default Card;