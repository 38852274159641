import React from 'react'
import Button from './Button'

const CategoryCard = ({img, title, link, classname}) => {
  return (
    <div className={`category ${classname}`}>

        <img src={img} alt=""  className='zoom'/>
        <div className="card_detail">
        <h3>{title}</h3>
        <a href={link}><Button name="VIEW  PRODUCT"/></a>
        </div>
       
      
    </div>
  )
}

export default CategoryCard
