import React from 'react'
import '../footer/footer.css'
import img from '../../images/logo.png'
import Button from '../../utility/Button'
import { BsTelephoneOutbound } from 'react-icons/bs'
import {CiLocationOn, CiMail} from 'react-icons/ci'
import {FaFacebook} from 'react-icons/fa'
import {GrTwitter} from 'react-icons/gr'
import {AiOutlineInstagram, AiFillLinkedin} from 'react-icons/ai'




const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="sb_footer section_padding">
          <div className="sb_footer-links">
            <div className="sb_footer-links_div">

             <img src={img} alt="" className='footer-logo'  style={{height: '60px', width: '160px'}}/>

            
                <p style={{width: '220px', lineHeight: '22px'}}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis libero sapiente quas illum vero impedit.
                </p>
              

             


            </div>


            <div className="sb_footer-links_div">
              <h4>
                Contact Us
              </h4>

              <a href='' >
                <p>
                <CiLocationOn />  Address:  Vaishali Nagar, Jaipur
                </p>
              </a>

              <a href='' >
                <p>
                <BsTelephoneOutbound />  +91 702021-34662
                </p>
              </a>

              <a href='' >
                <p>
                 <CiMail /> saurabh@gmail.com
                </p>
              </a>


            </div>


          <div className="sb_footer-links_div">
          <h4>Resources</h4>

<a href='' >
  <p>
    Home
  </p>
</a>

<a href='' >
  <p>
    About us
  </p>
</a>

<a href='' >
  <p>
    Shop
  </p>
</a>


<a href='' >
  <p>
    Contact us
  </p>
</a>


          </div>


          <div className="sb_footer-links_div">
          <h4>Keep In Touch</h4>
          
          

<input type="text" placeholder='Enter Your Email Address...' />


<br />
<br />
<Button name="Subscribe"/>
          </div>


{/* 
          <div className="sb_footer-links_div">
          <h4>Resources</h4>

         <div className="socialmedia">
          <p>Facebook</p>
          <p>Facebook</p>
          <p>Facebook</p>
          <p>Facebook</p>
         </div>
          </div> */}
          </div>


          <hr></hr>


          <div className="sb_footer-below">
            <div className="sb_footer-copyright">

              <p>
              © {new Date().getFullYear()} Uptimiseit. All Rights Reserved.
              </p>
            </div>

            <div className="sb_footer-below-links">
            <FaFacebook className='hello'/>
            <AiOutlineInstagram className='hello'/>
            <AiFillLinkedin className='hello'/>
            <GrTwitter className='hello' />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Footer
