import React from 'react'
import Navbar from './components/navbar/Navbar'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Gallery from './pages/gallery/Gallery'
import Contact from './pages/contact/Contact'
import Shop from './pages/shop/Shop'
import NotFound from './pages/notfound/NotFound'
import Footer from './components/footer/Footer'


const App = () => {
  return (
  <BrowserRouter>

  
      {/* <Hero /> */}
    <Navbar />
      <Routes>
        <Route index  element={<Home />} />
        <Route path='about'  element={<About />} />
        <Route path='gallery'  element={<Gallery />} />
        <Route path='contact'  element={<Contact />} />
        <Route path='shop'  element={<Shop />} />
        <Route path='about'  element={<NotFound />} />
      </Routes>

      
<Footer />
   </BrowserRouter>
  )
}

export default App
