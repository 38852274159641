import React from 'react'
import '../../components/instagram/instagram.css'
import img from '../../images/352212449_284307294033526_8229883180023222486_n.jpg'
import img1 from '../../images/352312814_274168658455606_3591120776072004295_n.jpg'
import img2 from '../../images/352435418_1222596585806005_4243640074969127098_n.jpg'
import img3 from '../../images/353815391_608538951283521_9161915027432842046_n.jpg'
import {BsInstagram} from 'react-icons/bs'

const Instagram = () => {
  return (
    <div className='instagram'>

    <h1>Follow us</h1>
    <h2>@i'mgoodindia</h2>

    <div className="insta-image">
      
      <div className="img_div">
      <img src={img} alt="" />
      <div className="blur">
        <small> <BsInstagram className='in' /></small>
       
         </div>
      </div>
      <div className="img_div">
      <img src={img1} alt="" />
      <div className="blur">
      <small> <BsInstagram className='in' /></small>
         </div>
      </div>
      

 <div className="img_div">
 <img src={img2} alt="" />
 <div className="blur">
 <small> <BsInstagram className='in' /></small>
         </div>
 </div>
        
 <div className="img_div"><img src={img3} alt="" />
 <div className="blur">
 <small>  <BsInstagram className='in' /></small>
         </div>
 </div>
         

         

         
    </div>
      
    </div>
  )
}

export default Instagram
