import React from 'react'
import CategoryCard from '../../utility/CategoryCard'
import data from '../data/categorycard'
import '../category/category.css'

const Category = () => {
  return (
    <div className='category-container'>
    {
        data.map(({img, title, link,id}) => {
          return(
                  <CategoryCard key={id} img={img}
                  
                  link={link} title={title}  />
          )
        })
    }
      
      
   </div>
  )
}

export default Category
