import React from 'react'
import img from '../../images/Screenshot (13).png'
import '../SecondBanner/secondbanner.css'
import Button from '../../utility/Button'

const SecondBanner = () => {
  return (
    <div className='second-ban'>
    <div className='ban-image'> <img src={img} alt="" /></div>

    <div>
    <div className="product-data">

        <h1>
        Our Signature Formula
        </h1>

<h3>NURTURES RADIANT SKIN AND HAPPY SPIRITS</h3>





<p>The SkinLov-4 Blend included in most of our products is a proprietary formulation that contains gentle yet efficacious skin care ingredients – Prickly Pear, Japanese Honeysuckle, Marigold and Licorice extracts - specially selected to nourish, fortify and soothe your skin alongside cleansing and exfoliating treatments.</p>






<Button name="Read More" />


</div>
    </div>
     
    </div>
  )
}

export default SecondBanner
