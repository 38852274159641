import React, {useRef, useEffect} from 'react'
import img from '../../images/seelove-banner-03_1800x.webp'
import '../../components/hero/hero.css'
import img1 from '../../images/120ml-Bottle-for-website-06_1800x.webp'
import { Swiper, SwiperSlide,} from 'swiper/react';
import {Autoplay} from 'swiper'
import {IoMdStar} from 'react-icons/io'
import 'swiper/css';
import 'swiper/css/autoplay'
import Button from '../../utility/Button';
import { motion } from "framer-motion"


const Hero = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    const handleAutoSlideChange = () => {
      swiperInstance.slides.forEach((slide) => {
        if (slide === swiperInstance.slides[swiperInstance.activeIndex]) {
          slide.style.transitionDuration = '3s';
          slide.style.transform = 'scale(1.10)';
        } else {
          slide.style.transitionDuration = '';
          slide.style.transform = '';
        }
      });
    };

    swiperInstance.on('slideChange', handleAutoSlideChange);

    return () => {
      swiperInstance.off('slideChange', handleAutoSlideChange);
    };
  }, []);
  return (
//     <div className='hero_banner'>
      
// <img src={img} alt="" />
//     </div>
<>
<div className='hero_banner'>
<Swiper
        ref={swiperRef}
        modules={[Autoplay]}
        autoplay={{ delay: 5000 }}
        slidesPerView={1}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <motion.img
            initial={{ scale: 1.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.6 }}
            src={img}
            alt=""
          />
          <div className='banner_head'>
            <p>Try!</p>
            <h1>I'm Good!</h1>
            <h2>MINIS KIT</h2>
            <p>
              5 minis perfect for travel, trial and everything in between, in a complimentary pouch
            </p>
            <h3>Now Buy 1 Get 1 Free</h3>
            <Button name="Shop Now" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <motion.img src={img1} alt="" />
          <div className='banner_head'>
            <p>I'm Good!</p>
            <h1>Gentle Makeup</h1>
            <h2>Cleansing Oil</h2>
            <p>cleanses Makeup and Impurities Effortlessly No Residue, No Dryness</p>
            <h3>Now in 120ml @ 15% Off</h3>
            <Button name="Shop Now" />
          </div>
        </SwiperSlide>
      </Swiper>
<div>
 
  <Button icon={< IoMdStar />} name="Reviews" className="reviews"/>
</div>
</div>




</>
  )
}

export default Hero
