import React from 'react'
import Hero from '../../components/hero/Hero'
import TopPicks from '../../components/toppicks/TopPicks'
import Category from '../../components/category/Category'
import Exclusive from '../../components/exclusive/Exclusive'
import Belive from '../../components/Belive/Belive'
import SecondBanner from '../../components/SecondBanner/SecondBanner'
import Instagram from '../../components/instagram/Instagram'



const Home = () => {
  return (
    <div>
  
  <Hero />
  <TopPicks />
  <Category  />
  <Exclusive />
  <Belive />
  <SecondBanner />
  <Instagram />
  

    </div>
  )
}

export default Home
