import React from 'react'
import {Stack, Rating } from '@mui/material'

const Star = () => {
  return (
    <div>
        <Stack spacing={2}>
            <Rating />
        </Stack>
      
    </div>
  )
}

export default Star

